// List group


// General

.input-group {
  --#{$prefix}input-group-padding-y: #{$input-group-padding-y};
  --#{$prefix}input-group-padding-x: #{$input-group-padding-x};
  --#{$prefix}input-group-gap: var(--#{$prefix}input-group-padding-x);
  --#{$prefix}input-group-border-width: #{$input-group-border-width};
  --#{$prefix}input-group-border-color: #{$input-group-border-color};
  --#{$prefix}input-group-border-radius: #{$input-group-border-radius};
  --#{$prefix}input-group-bg: #{$input-group-bg};

  flex-wrap: nowrap;
  gap: var(--#{$prefix}input-group-gap);
  padding: var(--#{$prefix}input-group-padding-y) var(--#{$prefix}input-group-padding-x);
  overflow: hidden;
  background-color: var(--#{$prefix}input-group-bg);
  border: var(--#{$prefix}input-group-border-width) solid var(--#{$prefix}input-group-border-color);
  @include border-radius(var(--#{$prefix}input-group-border-radius));

  .form-control,
  .form-select,
  .input-group-text {
    --#{$prefix}border-width: 0;
  }

  .form-control,
  .form-select {
    background-color: transparent;
  }

  .input-group-text {
    padding: $input-group-addon-padding-y $input-group-addon-padding-x;
  }

  .btn {
    @include border-radius(var(--#{$prefix}btn-border-radius) !important); // stylelint-disable-line declaration-no-important
  }
}


// Sizing

.input-group-sm {
  --#{$prefix}input-group-padding-y: #{$input-group-padding-y-sm};
  --#{$prefix}input-group-padding-x: #{$input-group-padding-x-sm};
  --#{$prefix}input-group-border-radius: #{$input-group-border-radius-sm};

  .btn-icon {
    --#{$prefix}btn-size: #{$btn-icon-size-sm};
  }
}

.input-group-lg {
  --#{$prefix}input-group-padding-y: #{$input-group-padding-y-lg};
  --#{$prefix}input-group-padding-x: #{$input-group-padding-x-lg};
  --#{$prefix}input-group-border-radius: #{$input-group-border-radius-lg};

  .btn-icon {
    --#{$prefix}btn-size: #{$btn-icon-size-lg};
  }

  .btn:not(.btn-icon) {
    --#{$prefix}btn-padding-x: #{$btn-padding-x-lg};

    padding: {
      right: var(--#{$prefix}btn-padding-x);
      left: var(--#{$prefix}btn-padding-x);
    }
  }
}


// Light variant

.input-group-light {
  --#{$prefix}input-group-bg: #{$input-group-light-bg};
  --#{$prefix}input-group-border-color: #{$input-group-light-border-color};
}
