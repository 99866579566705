// Image hover effects utilities


// Swap image

.swap-image {

  .swap-from,
  .swap-to {
    display: block;
    transition: opacity .3s ease-in-out;
  }

  .swap-to {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    opacity: 0;
  }

  &:hover,
  &.active {
    .swap-from {
      opacity: 0;
    }
    .swap-to {
      opacity: 1;
    }
  }

  // stylelint-disable
  @supports (-webkit-touch-callout: none) {
    .swap-from {
      opacity: 1 !important;
    }
    .swap-to {
      opacity: 0 !important;
    }
  }
  // stylelint-enable
}


// Zoom image effect

.zoom-effect-wrapper {
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
}

.zoom-effect-img {
  display: block;
  transition: transform .4s ease-in-out;
}

.zoom-effect:hover .zoom-effect-img {
  transform: translateZ(0) scale(1.05);
}


// Hover fade effect

@include media-breakpoint-up(lg) {
  .hover-fade {
    &:hover .hover-fade-item {
      opacity: 1;
    }
  }

  .hover-fade-item {
    opacity: 0;
    will-change: opacity;
    transition: opacity .15s ease-in-out;

  }
}


// Adaptive thumbnail image

.thumbnail-img {
  --#{$prefix}thumbnail-img-width-desktop: #{$thumbnail-img-width-desktop};
  --#{$prefix}thumbnail-img-width-mobile: #{$thumbnail-img-width-mobile};

  display: block;
  width: var(--#{$prefix}thumbnail-img-width-mobile);
  @include media-breakpoint-up(sm) {
    width: var(--#{$prefix}thumbnail-img-width-desktop);
  }
}
