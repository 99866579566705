// Steps

.steps-wrapper {
  --#{$prefix}steps-spacer-y: #{$steps-spacer-y};
  --#{$prefix}steps-spacer-x: #{$steps-spacer-x};

  display: flex;
  row-gap: var(--#{$prefix}steps-spacer-y);
  column-gap: var(--#{$prefix}steps-spacer-x);
}

.step-item {
  --#{$prefix}step-item-font-weight: #{$step-item-font-weight};
  --#{$prefix}step-item-color: #{$step-item-color};
  --#{$prefix}step-item-current-color: #{$step-item-current-color};
  --#{$prefix}step-item-active-color: #{$step-item-active-color};

  --#{$prefix}step-item-line-height: #{$step-item-line-height};
  --#{$prefix}step-item-line-border-radius: #{$step-item-line-border-radius};
  --#{$prefix}step-item-line-bg: #{$step-item-line-bg};
  --#{$prefix}step-item-current-line-bg: #{$step-item-current-line-bg};
  --#{$prefix}step-item-active-line-bg: #{$step-item-active-line-bg};

  display: flex;
  flex-direction: column;
  gap: var(--#{$prefix}steps-spacer-y);
  width: 100%;
  font-weight: var(--#{$prefix}step-item-font-weight);
  color: var(--#{$prefix}step-item-color);
  text-decoration: none;
  transition: color .25s ease-in-out;

  &::after {
    display: block;
    width: 100%;
    height: var(--#{$prefix}step-item-line-height);
    content: "";
    background-color: var(--#{$prefix}step-item-line-bg);
    transition: background-color .25s ease-in-out;
    @include border-radius(var(--#{$prefix}step-item-line-border-radius));
  }

  &.current {
    color: var(--#{$prefix}step-item-current-color);

    &::after {
      background-color: var(--#{$prefix}step-item-current-line-bg);
    }
  }

  &.active {
    color: var(--#{$prefix}step-item-active-color);

    &::after {
      background-color: var(--#{$prefix}step-item-active-line-bg);
    }
  }
}
