// Card

// General

.card {
  --#{$prefix}card-transition: #{$card-transition};
  transition: var(--#{$prefix}card-transition);
}

.card-body {
  @include media-breakpoint-down(lg) {
    --#{$prefix}card-spacer-y: #{$card-spacer-y * .5};
    --#{$prefix}card-spacer-x: #{$card-spacer-x * .5};
  }
}


// Hover effect

.card-hover {
  &:hover {
    @include box-shadow($card-hover-box-shadow);
  }
}


// Hover overflow effect

.card-hover-overflow {
  overflow: hidden;

  &:hover {
    .card-body-overflow {
      transform: translateZ(0) translateY(0);
    }
    .card-text {
      opacity: 1;
      transition-delay: .15s;
    }
  }
}

.card-body-overflow {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  transition: transform .3s ease-in-out;

  .card-title {
    --#{$prefix}card-title-spacer-y: 0;
  }

  .card-text {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    transform: translateZ(0) translateY(calc(100% - 5.5rem));

    .card-title {
      --#{$prefix}card-title-spacer-y: #{$card-title-spacer-y};
    }

    .card-text {
      display: block;
      opacity: 0;
      transition: opacity .3s ease-in-out;
    }
  }

  @include media-breakpoint-down(sm) {
    .card-title {
      font-size: $font-size-sm;
    }
  }
}


// Product card (item)

.card-product {
  --#{$prefix}card-border-color: #{$card-product-border-color};
  --#{$prefix}card-hover-border-color: #{$card-product-hover-border-color};

  position: relative;
  height: 100%;

  .card-img-top {
    position: relative;
  }

  .btn-wishlist {
    position: absolute;
    top: 0;
    right: 0;
  }

  .form-check-input,
  .form-check-label {
    cursor: pointer;
  }

  .card-title {
    --#{$prefix}card-title-spacer-y: #{$card-product-title-spacer-y};
    --#{$prefix}card-title-font-size: #{$card-product-title-font-size};

    font-size: var(--#{$prefix}card-title-font-size);
  }

  .card-text {
    --#{$prefix}card-text-spacer-y: #{$card-product-text-spacer-y};
    --#{$prefix}card-text-font-size: #{$card-product-text-font-size};

    margin-bottom: var(--#{$prefix}card-text-spacer-y);
    font-size: var(--#{$prefix}card-text-font-size);
    line-height: $line-height-base;
    text-underline-offset: $spacer * .25;

    &.text-line-clamp {
      --#{$prefix}text-line-clamp-lines: #{$card-product-text-lines};
      --#{$prefix}text-line-clamp-height: 3.875rem;
    }
  }

  .card-body {
    --#{$prefix}card-spacer-y: #{$card-product-spacer-y};
    --#{$prefix}card-spacer-x: #{$card-product-spacer-x};

    padding-bottom: calc(var(--#{$prefix}card-spacer-y) * 1.5);
  }

  .card-footer {
    --#{$prefix}card-cap-padding-y: #{$card-product-cap-spacer-y};
    --#{$prefix}card-cap-padding-x: #{$card-product-cap-spacer-x};

    padding: {
      top: calc(var(--#{$prefix}card-cap-padding-y) * .75);
      bottom: calc(var(--#{$prefix}card-cap-padding-y) * 1.5);
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: $spacer * .5;
      margin-top: -($card-cap-padding-y * .75);
    }
  }

  &:not(.card-product-static) {
    border-bottom: initial;

    .card-footer {
      position: absolute;
      top: 100%;
      left: calc(#{$card-border-width} * -1);
      z-index: 5;
      display: none;
      width: calc(100% + calc(#{$card-border-width} * 2));
      background-color: $card-bg;
      border: $card-border-width solid var(--#{$prefix}card-hover-border-color);
      border-top: initial;
      transition: $card-transition;
    }
  }

  @include media-breakpoint-up(lg) {
    &:hover {
      border-color: var(--#{$prefix}card-hover-border-color);
      @include box-shadow($box-shadow);

      .card-text.text-line-clamp {
        --#{$prefix}text-line-clamp-lines: #{$card-product-hover-text-lines};
      }
    }
    &:not(.card-product-static):hover {
      .card-footer {
        display: block;
        animation: product-card-footer-show .25s;
        @include box-shadow(0 2.6875rem 2.4375rem 0 rgba(59, 60, 66, .08));
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .card-body {
      --#{$prefix}card-spacer-y: #{$card-product-spacer-y * .5};
      --#{$prefix}card-spacer-x: 0;
    }
    .card-footer {
      padding: {
        right: 0;
        left: 0;
      }
      > div {
        margin-top: 0;
      }
    }
  }
}

@keyframes product-card-footer-show {
  from { opacity: 0; }
  to { opacity: 1; }
}
