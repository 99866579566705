// Override default Bootstrap's list-group component


.list-group {
  gap: initial;
}

.list-group-flush {
  --#{$prefix}list-group-item-padding-x: 0;
}


// List group item icon with hover effect

.list-group-icon {
  transition: transform .15s ease-in-out;
}

.list-group-item:hover {
  .list-group-icon {
    &.#{$icon-prefix}arrow-up-right {
      transform: translateZ(0) rotate(45deg);
    }
  }
}
