// Gallery grid


.gallery-grid {
  gap: $spacer * 1.25;

  @include media-breakpoint-down(md) {
    gap: $spacer;
  }
  @include media-breakpoint-down(sm) {
    gap: $spacer * .25;
  }
}
