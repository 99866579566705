//
// Carousel
// Based on https://github.com/nolimits4web/swiper
// --------------------------------------------------


// Navigation (Prev / Next) buttons

.btn-prev,
.btn-next {
  --#{$prefix}carousel-nav-btn-border-radius: #{$carousel-nav-btn-border-radius};
  --#{$prefix}carousel-nav-btn-border-color: #{$carousel-nav-btn-border-color};
  --#{$prefix}carousel-nav-btn-bg: #{$carousel-nav-btn-bg};
  --#{$prefix}carousel-nav-btn-color: #{$carousel-nav-btn-color};
  --#{$prefix}carousel-nav-btn-hover-border-color: #{$carousel-nav-btn-hover-border-color};
  --#{$prefix}carousel-nav-btn-hover-bg: #{$carousel-nav-btn-hover-bg};
  --#{$prefix}carousel-nav-btn-hover-color: #{$carousel-nav-btn-hover-color};
  --#{$prefix}carousel-nav-btn-disabled-color: rgba(#{to-rgb($carousel-nav-btn-color)}, .5);
  --#{$prefix}carousel-nav-btn-font-size: #{$font-size-base};

  font-size: var(--#{$prefix}carousel-nav-btn-font-size);
  color: var(--#{$prefix}carousel-nav-btn-color) !important; // stylelint-disable-line declaration-no-important
  background-color: var(--#{$prefix}carousel-nav-btn-bg);
  border-color: var(--#{$prefix}carousel-nav-btn-border-color);
  @include border-radius(var(--#{$prefix}carousel-nav-btn-border-radius));
  transition: $carousel-nav-btn-transition;

  &:focus {
    background-color: var(--#{$prefix}carousel-nav-btn-bg);
  }

  &:not(.bg-transparent):hover {
    color: var(--#{$prefix}carousel-nav-btn-hover-color) !important; // stylelint-disable-line declaration-no-important
    background-color: var(--#{$prefix}carousel-nav-btn-hover-bg) !important; // stylelint-disable-line declaration-no-important
    border-color: var(--#{$prefix}carousel-nav-btn-hover-border-color) !important; // stylelint-disable-line declaration-no-important
  }

  > i { font-size: 1em; }
  &.btn-sm > i { font-size: 1.125em; }
  &.btn-lg > i { font-size: 1.25em; }
}

.swiper-button-disabled,
.swiper-button-disabled:disabled {
  color: var(--#{$prefix}carousel-nav-btn-disabled-color) !important; // stylelint-disable-line declaration-no-important
  background-color: var(--#{$prefix}carousel-nav-btn-bg);
  opacity: 1;
}
.pagination {
  .swiper-button-disabled,
  .swiper-button-disabled:disabled {
    pointer-events: none;
    background-color: transparent !important; // stylelint-disable-line declaration-no-important
  }
}

.swiper {
  .btn-prev:not(.position-static),
  .btn-next:not(.position-static) {
    position: absolute;
    top: 50%;
    z-index: 5;
    transform: translateY(-50%);
  }
  .btn-prev {
    left: $spacer;
  }
  .btn-next {
    right: $spacer;
  }
}

.swiper-nav-onhover {
  .btn-prev,
  .btn-next {
    opacity: 0 !important; // stylelint-disable-line declaration-no-important
  }
  &:hover .btn-prev,
  &:hover .btn-next {
    opacity: 1 !important; // stylelint-disable-line declaration-no-important
  }
}


// Pagination

// Bullets

.swiper-pagination {
  --#{$prefix}carousel-pagination-bullet-width: #{$carousel-bullet-width};
  --#{$prefix}carousel-pagination-bullet-height: #{$carousel-bullet-height};
  --#{$prefix}carousel-pagination-bullet-spacer: #{$carousel-bullet-spacer};
  --#{$prefix}carousel-pagination-bullet-border-radius: #{$carousel-bullet-border-radius};
  --#{$prefix}carousel-pagination-bullet-bg: #{$carousel-bullet-bg};
  --#{$prefix}carousel-pagination-bullet-active-scale: #{$carousel-bullet-active-scale};
  --#{$prefix}carousel-pagination-bullet-active-bg: #{$carousel-bullet-active-bg};
  --#{$prefix}carousel-pagination-progressbar-height: #{$carousel-progressbar-height};
  --#{$prefix}carousel-pagination-progressbar-bg: #{$carousel-progressbar-bg};
  --#{$prefix}carousel-pagination-progressbar-fill-bg: #{$carousel-progressbar-fill-bg};

  bottom: $spacer * 1.25;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: {
    top: $spacer * .25;
    bottom: $spacer * .25;
  }

  &.swiper-pagination-lock { display: none; }

  &.swiper-pagination-light {
    --#{$prefix}carousel-pagination-bullet-bg: #{$carousel-bullet-bg-light};
    --#{$prefix}carousel-pagination-bullet-active-bg: #{$carousel-bullet-active-bg-light};
  }
}

.swiper-pagination-bullet {
  width: var(--#{$prefix}carousel-pagination-bullet-width);
  height: var(--#{$prefix}carousel-pagination-bullet-height);
  background-color: var(--#{$prefix}carousel-pagination-bullet-bg);
  opacity: 1;
  transition: $carousel-bullet-transition;
  @include border-radius(var(--#{$prefix}carousel-pagination-bullet-border-radius));
}

.swiper-pagination-bullet-active {
  background-color: var(--#{$prefix}carousel-pagination-bullet-active-bg);
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: $spacer * 1.25;
  .swiper-pagination-bullet {
    margin: {
      right: calc(var(--#{$prefix}carousel-pagination-bullet-spacer) * .5);
      left: calc(var(--#{$prefix}carousel-pagination-bullet-spacer) * .5);
    }
  }
  .swiper-pagination-bullet-active {
    transform: scale(var(--#{$prefix}carousel-pagination-bullet-active-scale));
  }
}

.swiper-vertical > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-vertical {
  right: $spacer * 1.25;
  flex-direction: column;
  .swiper-pagination-bullet {
    margin: {
      top: calc(var(--#{$prefix}carousel-pagination-bullet-spacer) * .5);
      bottom: calc(var(--#{$prefix}carousel-pagination-bullet-spacer) * .5);
    }
  }
  .swiper-pagination-bullet-active {
    transform: scale(var(--#{$prefix}carousel-pagination-bullet-active-scale));
  }
}


// Thumbnails (uppon md-breakpoint)

@include media-breakpoint-up(md) {
  .swiper-thumbnails {
    --#{$prefix}carousel-thumbnail-width: #{$carousel-thumbnail-width};
    --#{$prefix}carousel-thumbnail-border-width: #{$carousel-thumbnail-border-width};
    --#{$prefix}carousel-thumbnail-border-color: #{$carousel-thumbnail-border-color};
    --#{$prefix}carousel-thumbnail-active-border-color: #{$carousel-thumbnail-active-border-color};
    --#{$prefix}carousel-thumbnail-spacer: #{$carousel-thumbnail-spacer};

    position: static;
    display: flex;
    flex-wrap: wrap;
    gap: var(--#{$prefix}carousel-thumbnail-spacer);
  }

  .swiper-thumbnail {
    max-width: var(--#{$prefix}carousel-thumbnail-width);
    border: var(--#{$prefix}carousel-thumbnail-border-width) solid var(--#{$prefix}carousel-thumbnail-border-color);

    &.active {
      border-color: var(--#{$prefix}carousel-thumbnail-active-border-color);
    }

    &.thumbnail-badge::after {
      display: flex;
    }
  }
}

@include media-breakpoint-down(md) {
  .swiper-thumbnails-wrapper {
    position: absolute;
    bottom: $spacer * .75;
    left: 50%;
    z-index: 3;
    transform: translateX(-50%);
  }

  .swiper-thumbnails {
    --#{$prefix}carousel-pagination-bullet-width: #{$carousel-bullet-width};
    --#{$prefix}carousel-pagination-bullet-height: #{$carousel-bullet-height};
    --#{$prefix}carousel-pagination-bullet-spacer: #{$carousel-bullet-spacer};
    --#{$prefix}carousel-pagination-bullet-border-radius: #{$carousel-bullet-border-radius};
    --#{$prefix}carousel-pagination-bullet-bg: #{$carousel-bullet-bg};
    --#{$prefix}carousel-pagination-bullet-active-scale: #{$carousel-bullet-active-scale};
    --#{$prefix}carousel-pagination-bullet-active-bg: #{$carousel-bullet-active-bg};
    --#{$prefix}carousel-pagination-progressbar-height: #{$carousel-progressbar-height};
    --#{$prefix}carousel-pagination-progressbar-bg: #{$carousel-progressbar-bg};
    --#{$prefix}carousel-pagination-progressbar-fill-bg: #{$carousel-progressbar-fill-bg};

    bottom: $spacer * 1.25;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: {
      top: $spacer * .25;
      bottom: $spacer * .25;
    }
  }

  .swiper-thumbnail {
    width: var(--#{$prefix}carousel-pagination-bullet-width);
    height: var(--#{$prefix}carousel-pagination-bullet-height);
    background-color: var(--#{$prefix}carousel-pagination-bullet-bg);
    opacity: 1;
    transition: $carousel-bullet-transition;
    @include border-radius(var(--#{$prefix}carousel-pagination-bullet-border-radius));

    &.active {
      background-color: var(--#{$prefix}carousel-pagination-bullet-active-bg);
    }

    img {
      display: none;
    }
  }
}

@include media-breakpoint-down(sm) {
  .btn-wishlist-single {
    --#{$prefix}btn-size: #{$btn-icon-size-sm};
    margin: $spacer * .75 !important; // stylelint-disable-line declaration-no-important
  }
}


// Thumbnail badge

.thumbnail-badge {
  --#{$prefix}carousel-thumbnail-badge-size: #{$carousel-thumbnail-badge-size};
  --#{$prefix}carousel-thumbnail-badge-font-size: #{$carousel-thumbnail-badge-font-size};
  --#{$prefix}carousel-thumbnail-badge-border-color: #{$carousel-thumbnail-badge-border-color};
  --#{$prefix}carousel-thumbnail-badge-bg: #{$carousel-thumbnail-badge-bg};
  --#{$prefix}carousel-thumbnail-badge-border-radius: #{$carousel-thumbnail-badge-border-radius};

  position: relative;

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: none;
    width: var(--#{$prefix}carousel-thumbnail-badge-size);
    height: var(--#{$prefix}carousel-thumbnail-badge-size);
    content: "";
    background: {
      position: center;
      color: var(--#{$prefix}carousel-thumbnail-badge-bg);
      size: var(--#{$prefix}carousel-thumbnail-badge-font-size);
      repeat: no-repeat;
    }
    border: $border-width solid var(--#{$prefix}carousel-thumbnail-badge-border-color);
    transform: translateY(-50%) translateX(-50%);
    @include border-radius(var(--#{$prefix}carousel-thumbnail-badge-border-radius));
  }
}


// Thumbnail badge icons

// 3d-view icon
.thumbnail-badge-icon-3d::after {
  background-image: escape-svg($carousel-thumbnail-badge-icon-3d);
}

// video icon
.thumbnail-badge-icon-video::after {
  background-image: escape-svg($carousel-thumbnail-badge-icon-video);
}


// Product single features carousel

.swiper-features {
  @include media-breakpoint-down(md) {
    .swiper-slide {
      max-width: 13.25rem;
    }
  }
}
