// Override default Bootstrap's popover component

// Header

.popover-header {
  padding-bottom: initial;
  border-bottom: initial;
}


// Custom swatch popover

.popover-swatch {
  --#{$prefix}popover-max-width: 8rem;
  --#{$prefix}popover-body-padding-x: #{$spacer * .125};
  --#{$prefix}popover-body-padding-y: #{$spacer * .125};
  --#{$prefix}popover-border-radius: #{$border-radius-xxl};
}

.popover-swatch-title {
  display: block;
  width: calc(var(--#{$prefix}popover-max-width) - (var(--#{$prefix}popover-body-padding-x) * 2));
  margin: {
    top: $spacer * .25;
    bottom: $spacer * .125;
  }
  font: {
    size: $font-size-xs;
    weight: $font-weight-medium;
  }
  text-align: center;
}
