// Override Bootstrap's breadcrumb component

[aria-label="breadcrumb"] {
  --#{$prefix}breadcrumb-margin-bottom: #{$breadcrumb-margin-bottom};

  margin-bottom: calc(var(--#{$prefix}breadcrumb-margin-bottom) * .75);
}

.breadcrumb {
  --#{$prefix}breadcrumb-font-weight: #{$breadcrumb-font-weight};
  --#{$prefix}breadcrumb-color: #{$breadcrumb-color};
  --#{$prefix}breadcrumb-decoration: #{$breadcrumb-decoration};
  --#{$prefix}breadcrumb-hover-decoration: #{$breadcrumb-hover-decoration};
  --#{$prefix}breadcrumb-hover-color: #{$breadcrumb-hover-color};

  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0;
  margin-bottom: calc(var(--#{$prefix}breadcrumb-margin-bottom) * .25);
  font-weight: var(--#{$prefix}breadcrumb-font-weight);

  @include media-breakpoint-down(sm) {
    --#{$prefix}breadcrumb-font-size: #{$breadcrumb-font-size * .875};
  }
}

.breadcrumb-item {
  flex-shrink: 0;
  margin-bottom: calc(var(--#{$prefix}breadcrumb-margin-bottom) * .25);

  > a {
    color: var(--#{$prefix}breadcrumb-color);
    text-decoration: var(--#{$prefix}breadcrumb-color);
    white-space: nowrap;
    transition: $breadcrumb-transition;

    &:hover,
    &:focus-visible {
      color: var(--#{$prefix}breadcrumb-hover-color);
      text-decoration: var(--#{$prefix}breadcrumb-hover-decoration);
    }
  }
}
