// Typography related styles

h1,
.h1 {
  line-height: $h1-line-height;
}

h2,
.h2 {
  line-height: $h2-line-height;
}

h3,
.h3 {
  line-height: $h3-line-height;
}

h4,
.h4 {
  line-height: $h4-line-height;
}

h5,
.h5 {
  line-height: $h4-line-height;
}

h6,
.h6 {
  line-height: $h4-line-height;
}


// Blockquote

.blockquote {
  --#{$prefix}blockquote-padding-y: #{$blockquote-padding-y};
  --#{$prefix}blockquote-padding-x: #{$blockquote-padding-x};
  --#{$prefix}blockquote-bg: #{$blockquote-bg};
  --#{$prefix}blockquote-border-width: #{$blockquote-border-width};
  --#{$prefix}blockquote-border-color: #{$blockquote-border-color};

  padding: var(--#{$prefix}blockquote-padding-y) var(--#{$prefix}blockquote-padding-x);
  background-color: var(--#{$prefix}blockquote-bg);
  border-left: var(--#{$prefix}blockquote-border-width) solid var(--#{$prefix}blockquote-border-color);
}

.blockquote-footer {
  --#{$prefix}blockquote-footer-font-weight: #{$blockquote-footer-font-weight};

  margin-top: $blockquote-margin-y * .75;
  font-weight: var(--#{$prefix}blockquote-footer-font-weight);

  &::before {
    display: none;
  }

  cite {
    font-style: initial;
  }
}


// Inline list

.list-inline {
  flex-direction: row;
  flex-wrap: wrap;
  gap: $spacer * .625 $list-inline-padding;
  margin-bottom: $spacer * .75;
}


// Link inside heading

// stylelint-disable stylistic/selector-list-comma-newline-after
h1, .h1, h2, .h2, h3, .h3,
h4, .h4, h5, .h5, h6, .h6 {
  a {
    color: inherit;
    text-decoration: none;
    touch-action: manipulation;

    &:hover {
      text-decoration: underline;
    }
  }
}
// stylelint-enable stylistic/selector-list-comma-newline-after


// Catalog banners: Reducing font-size for mobile devices

@include media-breakpoint-down(sm) {
  .banner-title {
    font-size: .875rem !important; // stylelint-disable-line declaration-no-important
  }
  .banner-text {
    font-size: .625rem !important; // stylelint-disable-line declaration-no-important
  }
  .banner-btn {
    --#{$prefix}btn-padding-y: .375rem;
    --#{$prefix}btn-padding-x: .75rem;
    --#{$prefix}btn-font-size: .625rem;
  }
}


// Product single: Reducing font-size for mobile devices

.product-single-features {
  font-size: .875rem;
}
.product-single-page-title {
  font-size: 1rem;
}

.product-property-title,
.product-property-toggle {
  font-size: .875rem;
}

@include media-breakpoint-down(sm) {
  .product-single-features {
    gap: .75rem;
    font-size: .75rem;
  }
  .product-single-page-title {
    font-size: .875rem;
  }

  .product-property-title,
  .product-property-toggle {
    font-size: .75rem;
  }
}
