// Extend default Bootstrap's .form-control / .form-select classes

.form-control,
.form-select {
  --#{$prefix}form-control-bg: #{$input-bg};
  --#{$prefix}form-control-border-color: #{$input-border-color};
  --#{$prefix}form-control-focus-bg: #{$input-focus-bg};
  --#{$prefix}form-control-focus-border-color: #{$input-focus-border-color};

  background-color: var(--#{$prefix}form-control-bg);
  border-color: var(--#{$prefix}form-control-border-color);

  &:focus {
    background-color: var(--#{$prefix}form-control-focus-bg);
    border-color: var(--#{$prefix}form-control-focus-border-color);
  }

  &:disabled {
    border-style: dashed;
  }
}


// Textarea rounding fix

textarea {
  @include border-radius($border-radius-xl !important);
}


// Remove default -webkit search input clear button

.form-control {
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}


// Switch

.form-switch {
  min-height: $form-switch-height;
  margin-bottom: $form-switch-margin-bottom;

  .form-check-input {
    height: $form-switch-height;
    margin-top: 0;
    background-color: $form-switch-bg-color;
    border: 0 !important; // stylelint-disable-line declaration-no-important

    &:checked {
      background-color: $form-switch-checked-bg-color !important; // stylelint-disable-line declaration-no-important
    }
  }
}


// Password toggle

.password-toggle {
  position: relative;

  .form-control {
    padding-right: $input-padding-x * 2 + $input-font-size;
  }
}

.password-toggle-button {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 $input-padding-x;
  color: $password-toggle-button-color;
  cursor: pointer;
  transition: color .2s ease-in-out;

  &:hover {
    color: $password-toggle-button-hover-color;
  }
}


// Forms light version

// Input & select

.form-control-light,
.form-select-light {
  color: $input-light-color;
  background-color: $input-light-bg;
  border-color: $input-light-border-color;

  &::placeholder {
    color: $input-light-placeholder-color;
  }

  &:focus {
    color: $input-light-color;
    background-color: $input-light-focus-bg;
    border-color: $input-light-focus-border-color;
    @include box-shadow($input-light-focus-box-shadow);

    &::placeholder {
      color: $input-light-focus-placeholder-color;
    }
  }

  &:disabled {
    color: $input-light-disabled-color;
    background-color: $input-light-disabled-bg;
    opacity: $input-light-disabled-opacity;

    &::placeholder {
      color: $input-light-disabled-placeholder-color;
    }
  }
}


// Select

.form-select-light {
  background-image: escape-svg($form-select-light-indicator);
  &:focus {
    background-image: escape-svg($form-select-light-focus-indicator);
  }
  option { color: initial; }
}


// Custom select

.form-custom-select .form-select {
  text-align: left;

  &.show:not(.is-invalid) {
    border-color: var(--#{$prefix}form-control-focus-border-color);
  }
}


// Range slider

.range-slider {
  --#{$prefix}range-slider-height: #{$range-slider-height};
  --#{$prefix}range-slider-bg: #{$range-slider-bg};
  --#{$prefix}range-slider-connect-bg: #{$range-slider-connect-bg};
  --#{$prefix}range-slider-handle-size: #{$range-slider-handle-size};
  --#{$prefix}range-slider-handle-bg: #{$range-slider-handle-bg};
  --#{$prefix}range-slider-handle-active-bg: #{$range-slider-handle-active-bg};
  --#{$prefix}range-slider-handle-border-width: #{$range-slider-handle-border-width};
  --#{$prefix}range-slider-handle-border-color: #{$range-slider-handle-border-color};
  --#{$prefix}range-slider-handle-border-radius: 50%;
  --#{$prefix}range-slider-pips-color: var(--#{$prefix}body-color);
  --#{$prefix}range-slider-pips-font-size: #{$range-slider-pips-font-size};
  --#{$prefix}range-slider-pips-border-width: var(--#{$prefix}border-width);
  --#{$prefix}range-slider-pips-border-color: #{darken($border-color, 6%)};
  --#{$prefix}range-slider-tooltip-spacer: #{$range-slider-tooltip-spacer};
  --#{$prefix}range-slider-tooltip-padding-y: #{$range-slider-tooltip-padding-y};
  --#{$prefix}range-slider-tooltip-padding-x: #{$range-slider-tooltip-padding-x};
  --#{$prefix}range-slider-tooltip-bg: #{$range-slider-tooltip-bg};
  --#{$prefix}range-slider-tooltip-color: #{$range-slider-tooltip-color};
  --#{$prefix}range-slider-tooltip-font-size: #{$range-slider-tooltip-font-size};
  --#{$prefix}range-slider-tooltip-border-width: #{$range-slider-tooltip-border-width};
  --#{$prefix}range-slider-tooltip-border-color: #{$range-slider-tooltip-border-color};
  --#{$prefix}range-slider-tooltip-border-radius: #{$range-slider-tooltip-border-radius};
}

/* stylelint-disable selector-class-pattern */
.range-slider-ui {
  height: var(--#{$prefix}range-slider-height);
  margin: 0;
  margin: {
    top: 2.25rem;
    right: 0;
    bottom: 1.75rem;
    left: 0;
  }
  background-color: var(--#{$prefix}range-slider-bg);
  border: 0;
  box-shadow: none;

  .noUi-connect {
    background-color: var(--#{$prefix}range-slider-connect-bg);
  }

  .noUi-handle {
    top: 50%;
    width: var(--#{$prefix}range-slider-handle-size);
    height: var(--#{$prefix}range-slider-handle-size);
    margin-top: calc(var(--#{$prefix}range-slider-handle-size) * -.5);
    background-color: var(--#{$prefix}range-slider-handle-bg);
    border: var(--#{$prefix}range-slider-handle-border-width) solid var(--#{$prefix}range-slider-handle-border-color);
    border-radius: var(--#{$prefix}range-slider-handle-border-radius);
    box-shadow: none;

    &::before,
    &::after {
      display: none;
    }

    &:active,
    &:focus-visible {
      background-color: var(--#{$prefix}range-slider-handle-active-bg);
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      box-shadow: $focus-ring-box-shadow;
    }
  }

  .noUi-marker-normal {
    display: none;
  }

  .noUi-marker-horizontal {
    &.noUi-marker {
      width: var(--#{$prefix}range-slider-pips-border-width);
      background-color: var(--#{$prefix}range-slider-pips-border-color);
    }

    &.noUi-marker-large { height: .75rem; }
  }

  .noUi-value {
    padding-top: .125rem;
    font-size: var(--#{$prefix}range-slider-pips-font-size);
    color: var(--#{$prefix}range-slider-pips-color);
  }

  .noUi-tooltip {
    padding: var(--#{$prefix}range-slider-tooltip-padding-y) var(--#{$prefix}range-slider-tooltip-padding-x);
    margin-bottom: var(--#{$prefix}range-slider-tooltip-spacer);
    line-height: 1.2;
    font: {
      size: var(--#{$prefix}range-slider-tooltip-font-size);
      weight: $font-weight-medium;
    }
    color: var(--#{$prefix}range-slider-tooltip-color);
    background-color: var(--#{$prefix}range-slider-tooltip-bg);
    border: var(--#{$prefix}range-slider-tooltip-border-width) solid var(--#{$prefix}range-slider-tooltip-border-color);
    @include border-radius(var(--#{$prefix}range-slider-tooltip-border-radius));
  }
}
html:not([dir="rtl"]) .range-slider-ui.noUi-horizontal .noUi-handle {
  right: calc(var(--#{$prefix}range-slider-handle-size) * -.5);
}
/* stylelint-enable selector-class-pattern */


// Count input

.count-input {
  display: inline-flex;
  transition: border-color .3s ease-in-out;

  .form-control {
    width: $btn-icon-size;
    padding: {
      right: 0;
      left: 0;
    }
    text-align: center;

    /* stylelint-disable property-no-vendor-prefix */
    -moz-appearance: textfield;
    appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
    /* stylelint-enable property-no-vendor-prefix */
  }

  // Hover effect

  &:hover {
    border-color: $gray-900;
  }

  // Increment-decrement buttons

  .btn {
    --#{$prefix}btn-color: #{$gray-900};
    --#{$prefix}btn-hover-color: #{$gray-900};
    --#{$prefix}btn-active-color: #{$gray-900};
    --#{$prefix}btn-disabled-color: #{$gray-700};

    --#{$prefix}btn-bg: transparent;
    --#{$prefix}btn-hover-bg: #{$gray-200};
    --#{$prefix}btn-active-bg: #{$gray-200};
    --#{$prefix}btn-disabled-bg: transparent;

    --#{$prefix}btn-border-color: transparent;
    --#{$prefix}btn-hover-border-color: #{$gray-200};
    --#{$prefix}btn-active-border-color: #{$gray-200};
    --#{$prefix}btn-disabled-border-color: transparent;

    > i {
      font-size: 1.25em;
    }
  }

  // Sizing

  &.input-group-sm {
    .form-control {
      width: $btn-icon-size-sm;
    }
  }

  &.input-group-lg {
    .form-control {
      width: $btn-icon-size-lg;
    }
  }
}


// Form validation

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: var(--#{$prefix}form-control-border-color);

  &:focus {
    border-color: var(--#{$prefix}form-control-focus-border-color);
  }
}

.was-validated .password-toggle .form-control:valid,
.password-toggle .form-control.is-valid,
.was-validated .password-toggle .form-control:invalid,
.password-toggle .form-control.is-invalid {
  background-position: $form-select-feedback-icon-position;
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border: $form-check-input-border;

  &:checked {
    background-color: $form-check-input-checked-bg-color;
    border-color: $form-check-input-checked-border-color;
  }

  &:focus {
    box-shadow: none;
  }

  ~ .form-check-label {
    color: var(--#{$prefix}body-color);
  }
}

.was-validated .form-switch .form-check-input:invalid,
.form-switch .form-check-input.is-invalid {
  background-color: var(--#{$prefix}form-invalid-color) !important; // stylelint-disable-line declaration-no-important
}

.valid-tooltip {
  color: var(--#{$prefix}form-valid-color);
  background-color: var(--#{$prefix}success-bg-subtle);
}

.invalid-tooltip {
  color: var(--#{$prefix}form-invalid-color);
  background-color: var(--#{$prefix}danger-bg-subtle);
}

.was-validated .form-custom-select {
  &:has(:invalid),
  &:has(:valid) {
    .btn {
      background: {
        position: right 2.5rem center, center right 2.5rem;
        size: $form-select-feedback-icon-size;
        repeat: no-repeat;
      }
    }
  }

  &:has(:invalid) .btn:not(.btn-link) {
    background-image: escape-svg($form-feedback-icon-invalid);
    border-color: #{$form-invalid-border-color};
  }
  &:has(:valid) .btn:not(.btn-link) {
    background-image: escape-svg($form-feedback-icon-valid);
  }
}


// Form-check highlight as card

.card.form-check-highlight {
  --#{$prefix}card-active-border-color: #{$card-active-border-color};

  &.active {
    --#{$prefix}card-border-color: var(--#{$prefix}card-active-border-color);

    .hover-fade-item {
      opacity: 1;
    }
  }
}


// Credit card interactive icon

// stylelint-disable declaration-no-important
.credit-card-icon {
  width: 40px;
  height: 26px;
  background: {
    image: url(../img/payment/credit-cards.png);
    repeat: no-repeat;
    size: cover;
    position: 0 0;
  }

  &.amex {
    background-position: 0 -52px !important;
  }
  &.diners {
    background-position: 0 -78px !important;
  }
  &.discover {
    background-position: 0 -104px !important;
  }
  &.jcb {
    background-position: 0 -130px !important;
  }
  &.mastercard {
    background-position: 0 -156px !important;
  }
  &.visa {
    background-position: 0 -182px !important;
  }
}
// stylelint-enable declaration-no-important
