// Modals

.product-quick-view-sidebar {
  height: 100%;
  max-height: 564px;

  @include media-breakpoint-down(xl) {
    max-height: 466px;
  }
}
