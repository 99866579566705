// Override default Bootstrap's button-group component

.btn-group-alt {
  --#{$prefix}btn-group-spacer: #{$btn-group-spacer};
  --#{$prefix}btn-group-border-radius: #{$btn-group-border-radius};
  --#{$prefix}btn-group-bg: #{$btn-group-bg};

  gap: var(--#{$prefix}btn-group-spacer);
  padding: var(--#{$prefix}btn-group-spacer);
  background-color: var(--#{$prefix}btn-group-bg);
  @include border-radius(var(--#{$prefix}btn-group-border-radius));

  > .btn {
    flex-basis: 0;
    flex-grow: 1;
    margin: initial !important; // stylelint-disable-line declaration-no-important
    @include border-radius($btn-border-radius !important); // stylelint-disable-line declaration-no-important
  }

  // Sizing

  &.btn-group-sm {
    --#{$prefix}btn-group-spacer: #{$btn-group-spacer-sm};
    --#{$prefix}btn-group-border-radius: #{$btn-group-border-radius-sm};

    .btn {
      @include border-radius($btn-border-radius-sm !important); // stylelint-disable-line declaration-no-important
    }
  }

  &.btn-group-lg {
    --#{$prefix}btn-group-spacer: #{$btn-group-spacer-lg};
    --#{$prefix}btn-group-border-radius: #{$btn-group-border-radius-lg};

    .btn {
      @include border-radius($btn-border-radius-lg !important); // stylelint-disable-line declaration-no-important
    }
  }
}
