// Root CSS variables

:root,
[data-bs-theme="light"] {

  // Theme colors
  --#{$prefix}component-color: #{$component-color};
  --#{$prefix}component-hover-color: #{$component-hover-color};
  --#{$prefix}component-hover-bg: #{$component-hover-bg};
  --#{$prefix}component-active-color: #{$component-active-color};
  --#{$prefix}component-active-bg: #{$component-active-bg};
  --#{$prefix}component-disabled-color: #{$component-disabled-color};

  // User selection color
  --#{$prefix}user-selection-color: #{$user-selection-color};

  // Add xs border-radius
  --#{$prefix}border-radius-xs: #{$border-radius-xs};
}
