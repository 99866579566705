// Extend default Bootstrap's .nav, .nav-item, .nav-link classes

.nav {
  --#{$prefix}nav-link-line-height: #{$nav-link-line-height};
  --#{$prefix}nav-link-active-color: #{$nav-link-active-color};

  flex-direction: row;
  gap: 0;
}

.nav-link {
  display: flex;
  align-items: center;

  &.show {
    color: var(--#{$prefix}nav-link-hover-color);
  }

  &.active {
    color: var(--#{$prefix}nav-link-active-color);
  }

  &.disabled,
  &:disabled {
    color: var(--#{$prefix}nav-link-disabled-color);
  }
}


// Light variant

.nav.nav-light,
.nav-link.nav-link-light {
  --#{$prefix}nav-link-color: #{$nav-link-light-color};
  --#{$prefix}nav-link-hover-color: #{$nav-link-light-hover-color};
  --#{$prefix}nav-link-active-color: #{$nav-link-light-active-color};
  --#{$prefix}nav-link-disabled-color: #{$nav-link-light-disabled-color};
  --#{$prefix}nav-link-font-weight: #{$nav-link-light-font-weight};
}

.nav-link.nav-link-light {
  u::after {
    background-color: $nav-link-light-hover-color;
  }
}


// Nav-column padding

.nav.flex-column:not(.nav-underline):not(.nav-pills) {
  --#{$prefix}nav-link-padding-x: initial;
  --#{$prefix}nav-link-padding-y: initial;

  gap: $spacer * .5;
}


// Tabs

.nav-tabs {
  --#{$prefix}nav-tabs-padding: #{$nav-tabs-padding};
  --#{$prefix}nav-tabs-bg: #{$nav-tabs-bg};
  --#{$prefix}nav-tabs-link-hover-bg: #{$nav-tabs-link-hover-bg};
  --#{$prefix}nav-tabs-link-active-box-shadow: #{$nav-tabs-link-active-box-shadow};

  gap: var(--#{$prefix}nav-tabs-padding);
  padding: var(--#{$prefix}nav-tabs-padding);
  background-color: var(--#{$prefix}nav-tabs-bg);
  @include border-radius(var(--#{$prefix}nav-tabs-border-radius));

  .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }

  .nav-link {
    justify-content: center;
    width: 100%;
    line-height: var(--#{$prefix}nav-link-line-height);
    @include border-radius(var(--#{$prefix}nav-tabs-border-radius));

    &:hover,
    &.show,
    &:focus-visible {
      background-color: var(--#{$prefix}nav-tabs-link-hover-bg);
    }

    &:focus-visible {
      box-shadow: $nav-link-focus-box-shadow !important; // stylelint-disable-line declaration-no-important
    }

    &.active {
      background-color: var(--#{$prefix}nav-tabs-link-active-bg);
      @include box-shadow(var(--#{$prefix}nav-tabs-link-active-box-shadow));
    }

    &.disabled,
    &:disabled {
      background: none;
    }
  }

  &.flex-column .nav-link {
    justify-content: start;
  }

  .dropdown-menu {
    @include border-radius(var(--#{$prefix}dropdown-border-radius));
  }
}


// Pills

.nav-pills {
  --#{$prefix}nav-pills-gap: #{$nav-pills-gap};

  --#{$prefix}nav-link-padding-y: #{$nav-pills-link-padding-y};
  --#{$prefix}nav-link-padding-x: #{$nav-pills-link-padding-x};

  --#{$prefix}nav-link-color: #{$nav-pills-link-color};
  --#{$prefix}nav-link-hover-color: #{$nav-pills-link-hover-color};
  --#{$prefix}nav-link-active-color: #{$nav-pills-link-active-color};
  --#{$prefix}nav-link-disabled-color: #{$nav-pills-link-disabled-color};

  --#{$prefix}nav-pills-link-bg: #{$nav-pills-link-bg};
  --#{$prefix}nav-pills-link-hover-bg: #{$nav-pills-link-hover-bg};

  --#{$prefix}nav-pills-link-border-width: #{$nav-pills-link-border-width};
  --#{$prefix}nav-pills-link-border-color: #{$nav-pills-link-border-color};
  --#{$prefix}nav-pills-link-hover-border-color: #{$nav-pills-link-hover-border-color};
  --#{$prefix}nav-pills-link-active-border-color: #{$nav-pills-link-active-border-color};
  --#{$prefix}nav-pills-link-disabled-border-color: #{$nav-pills-link-disabled-border-color};

  gap: var(--#{$prefix}nav-pills-gap);

  .nav-link {
    background-color: var(--#{$prefix}nav-pills-link-bg);
    border: var(--#{$prefix}nav-pills-link-border-width) solid var(--#{$prefix}nav-pills-link-border-color);

    &:hover:not(.active) {
      background-color: var(--#{$prefix}nav-pills-link-hover-bg);
      border-color: var(--#{$prefix}nav-pills-link-hover-border-color);
    }

    &.active {
      border-color: var(--#{$prefix}nav-pills-link-active-border-color);
    }

    &.disabled,
    &:disabled {
      background-color: var(--#{$prefix}nav-pills-link-disabled-bg);
      border-color: var(--#{$prefix}nav-pills-link-disabled-border-color);
    }
  }
}


// Underline

.nav-underline {
  gap: var(--#{$prefix}nav-underline-gap);

  &.flex-column {
    gap: 0;

    .nav-link {
      --#{$prefix}nav-link-padding-y: #{$spacer};
      --#{$prefix}nav-link-padding-x: #{$spacer};

      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: var(--#{$prefix}nav-link-padding-y) var(--#{$prefix}nav-link-padding-x);
      text-align: left;
      border-bottom: 0;
      border-left: $border-width * 3 solid $border-color;

      &.active {
        border-left-color: $dark;
      }

      @include media-breakpoint-up(lg) {
        --#{$prefix}nav-link-padding-y: #{$spacer * 2};
        --#{$prefix}nav-link-padding-x: #{$spacer * 2};
      }
    }
  }
}


// Page nav spacer (quick links)

.page-nav {
  --#{$prefix}nav-link-font-size: #{$font-size-base};
  column-gap: $spacer * 2;

  @include media-breakpoint-down(md) {
    column-gap: $spacer * 1.5;
  }
  @include media-breakpoint-down(sm) {
    --#{$prefix}nav-link-font-size: #{$font-size-sm};
    column-gap: $spacer;
  }
}
