// Override default Bootstrap's offcanvas component


// Body

.offcanvas-body {
  text-wrap: initial;
}


// Custom class toggle

.offcanvas-submenu {
  visibility: visible;
  transition-duration: .4s;

  &.offcanvas-show {
    transform: translateX(0);
  }
}
