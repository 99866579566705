// Override default Bootstrap's accordion component

.accordion {
  --#{$prefix}accordion-btn-font-size: #{$accordion-button-font-size};
  --#{$prefix}accordion-btn-font-weight: #{$accordion-button-font-weight};
  --#{$prefix}accordion-body-font-size: #{$accordion-body-font-size};
}

.accordion-button {
  @include font-size(var(--#{$prefix}accordion-btn-font-size));
  font-weight: var(--#{$prefix}accordion-btn-font-weight);

  &:focus-visible {
    @include box-shadow($focus-ring-box-shadow);
  }

  &:not(.collapsed) {
    box-shadow: none;
  }
}

.accordion-item {
  border: 0;
  border-bottom: var(--#{$prefix}accordion-border-width) solid var(--#{$prefix}accordion-border-color);
}

.accordion-body {
  padding-bottom: var(--#{$prefix}accordion-btn-padding-y);
  @include font-size(var(--#{$prefix}accordion-body-font-size));
}


// Collapse label hidden on expand

.hidden-content-item {
  display: none;
}

.form-check-highlight.active {
  .hidden-content-item {
    display: block;
  }
}


// Collapse label

.collapse-label {
  position: relative;

  &::before {
    content: attr(data-bs-label-expanded);
  }

  > [class^='#{$icon-prefix}'],
  > [class*=' #{$icon-prefix}'] {
    transition: transform .15s ease-in-out;
    transform: rotate(180deg);
  }

  // Collapse label icon (plus-minus) - only for .btn-link

  .collapse-label-icon {
    display: inline-block;
    width: 1.125em;
    height: 1.125em;
    background: {
      image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'><path fill='currentColor' d='M19.5 12a.624.624 0 0 1-.625.625H5.125a.625.625 0 1 1 0-1.25h13.75A.624.624 0 0 1 19.5 12Z'/></svg>"));
      size: 1.125em;
      position: center;
      repeat: no-repeat;
    }
  }

  &.collapsed {
    > [class^='#{$icon-prefix}'],
    > [class*=' #{$icon-prefix}'] { transform: none; }

    &::before {
      content: attr(data-bs-label-collapsed);
    }

    .collapse-label-icon {
      background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'><path fill='currentColor' d='M21 12a.75.75 0 0 1-.75.75h-7.5v7.5a.75.75 0 1 1-1.5 0v-7.5h-7.5a.75.75 0 1 1 0-1.5h7.5v-7.5a.75.75 0 1 1 1.5 0v7.5h7.5A.75.75 0 0 1 21 12Z'/></svg>"));
    }
  }
}


// Binded content

.binded-content:not(.active) {
  display: none;
}
