// Override default Bootstrap's dropdown component

// Dropdown toggle

.dropdown-toggle {
  display: flex;
  align-items: center;

  @if $enable-caret {
    &::after {
      width: $caret-width;
      height: $caret-width;
      margin-top: -.0625rem;
      border-color: currentcolor !important; // stylelint-disable-line declaration-no-important
      border-style: solid !important; // stylelint-disable-line declaration-no-important
      border-width: 0 .1em .1em 0;
      transform: rotate(45deg);
    }
  }
}

@if $enable-caret {
  .dropup .dropdown-toggle::after {
    margin-top: .25rem;
    border-width: .1em 0 0 .1em;
  }

  .dropend .dropdown-toggle::after {
    margin-top: .0625rem;
    border-width: .1em .1em 0 0;
  }

  .dropstart .dropdown-toggle {
    &::before {
      width: $caret-width;
      height: $caret-width;
      margin-top: .0625rem;
      border-color: currentcolor !important; // stylelint-disable-line declaration-no-important
      border-style: solid !important; // stylelint-disable-line declaration-no-important
      border-width: 0  0 .1em .1em;
      transform: rotate(45deg);
    }
  }

  .dropdown-toggle-split::after {
    margin: -.125rem 0 0;
  }

  .dropup .dropdown-toggle-split::after {
    margin: .125rem 0 0;
  }

  .dropend .dropdown-toggle-split::after {
    margin: 0;
  }

  .dropstart .dropdown-toggle-split::before {
    margin: 0;
  }
}

// Dropdown menu

.dropdown-menu {
  --#{$prefix}dropdown-item-border-radius: #{$dropdown-item-border-radius};
  --#{$prefix}dropdown-item-spacer: #{$dropdown-item-spacer};

  padding-bottom: calc(var(--#{$prefix}dropdown-padding-y) - var(--#{$prefix}dropdown-item-spacer));
  animation: fade-in .2s ease-in-out;

  &.show {
    animation: fade-in .15s ease-in-out;
  }
}

[data-bs-toggle="dropdown-hover"] + .dropdown-menu {
  &::before,
  &::after {
    position: absolute;
    display: block;
    content: "";
  }
}

.dropdown,
.dropup {
  .dropdown-menu {
    margin: var(--#{$prefix}dropdown-spacer) 0 !important; // stylelint-disable-line declaration-no-important
  }

  [data-bs-toggle="dropdown-hover"] + .dropdown-menu {
    &::before,
    &::after {
      left: 0;
      width: 100%;
      height: calc(var(--#{$prefix}dropdown-spacer) * 2);
    }
    &::before {
      bottom: 100%;
    }
    &::after {
      top: 100%;
    }
  }
}

.dropstart,
.dropend {
  .dropdown-menu {
    margin: 0 var(--#{$prefix}dropdown-spacer) !important; // stylelint-disable-line declaration-no-important
  }

  [data-bs-toggle="dropdown-hover"] + .dropdown-menu {
    &::before,
    &::after {
      top: 0;
      width: calc(var(--#{$prefix}dropdown-spacer) * 2);
      height: 100%;
    }
    &::before {
      left: calc(var(--#{$prefix}dropdown-spacer) * -2);
    }
    &::after {
      left: 100%;
    }
  }
}

.dropdown-item {
  display: flex;
  align-items: center;
  margin-bottom: var(--#{$prefix}dropdown-item-spacer);
  @include border-radius(var(--#{$prefix}dropdown-item-border-radius));
  transition: $dropdown-link-transition;

  &.show,
  [data-bs-toggle].show > &,
  [data-bs-toggle]:hover > &,
  &:active {
    color: var(--#{$prefix}dropdown-link-hover-color);
    background-color: var(--#{$prefix}dropdown-link-hover-bg);
  }

  &:focus-visible {
    outline: none;
    box-shadow: $focus-ring-box-shadow;
  }

  &.dropdown-toggle::after {
    margin-left: auto;
  }

  .item-active-indicator {
    display: none;
    font-size: 1.146em;
  }

  &.active {
    .item-active-indicator {
      display: flex;
      padding-left: .5rem;
    }

    + .btn-remove {
      --#{$prefix}btn-color: #{$white};
      --#{$prefix}btn-hover-color: #{$white};
      --#{$prefix}btn-active-color: #{$white};
      --#{$prefix}btn-hover-border-color: #{$white};
    }
  }
}

// Remove option btn
.btn-remove {
  --#{$prefix}btn-color: #{$dropdown-link-color};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-hover-bg: transparent;
  --#{$prefix}btn-active-bg: transparent;
  --#{$prefix}btn-border-color: transparent;
  --#{$prefix}btn-hover-border-color: #{$border-color};
  --#{$prefix}btn-active-border-color: transparent;
}


// Dropdown menu animation

@keyframes fade-in  {
  from { opacity: 0; }
  to { opacity: 1; }
}
