// Navbar

.navbar {
  min-height: 4.125rem;

  // Navbar container
  .container {
    display: block;
  }

  // Navbar offcanvas
  .offcanvas-nav {
    --#{$prefix}offcanvas-width: 21.5rem;
    --#{$prefix}offcanvas-padding-y: #{$spacer};
    --#{$prefix}offcanvas-padding-x: #{$spacer};

    // Navbar nav-column
    .nav.flex-column {
      gap: $spacer * .75;
    }
  }

  // Navbar nav-pills
  .nav-pills {
    .nav-link {
      --#{$prefix}navbar-nav-link-padding-y: #{$navbar-nav-pills-link-padding-y};
      --#{$prefix}navbar-nav-link-padding-x: #{$navbar-nav-pills-link-padding-x};
      --#{$prefix}navbar-active-color: #{$nav-pills-link-active-color};
      --#{$prefix}nav-link-font-size: #{$font-size-base};
      --#{$prefix}nav-pills-border-radius: #{$navbar-nav-pills-border-radius};
    }
  }

  // Toolbar buttons
  .btn-icon {
    .badge:empty {
      display: none !important; // stylelint-disable-line declaration-no-important
    }
  }

  // Mobile
  @include media-breakpoint-down(sm) {
    .btn-icon {
      --#{$prefix}btn-size: #{$btn-icon-size-sm};

      margin-left: $spacer * .125;
    }
  }
}


// Navbar sticky | Offcanvas

.site-header {
  &.sticky-top {
    z-index: $zindex-sticky + 1;
  }

  .offcanvas {
    @include media-breakpoint-down(sm) {
      --#{$prefix}offcanvas-width: 100vw;
    }
  }
}


// Toggler

.navbar-toggler {
  --#{$prefix}navbar-toggler-border-color: transparent;

  position: relative;
  width: $navbar-toggler-bar-width + $navbar-toggler-padding-x * 2;
  height: $navbar-toggler-bar-height * 3 + $navbar-toggler-bar-spacing * 2 + $navbar-toggler-padding-y * 2;
}

.navbar-toggler-icon {
  top: 50%;
  display: block;
  margin-top: $navbar-toggler-bar-height * -.5;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  transition-duration: .075s;

  &,
  &::before,
  &::after {
    position: absolute;
    width: $navbar-toggler-bar-width;
    height: $navbar-toggler-bar-height;
    background-color: var(--#{$prefix}navbar-color);
    transition-property: transform;
  }
  &::before,
  &::after {
    display: block;
    content: "";
  }
  &::before {
    top: ($navbar-toggler-bar-spacing + $navbar-toggler-bar-height) * -1;
    transition: top .075s .12s ease, opacity .075s ease, width .15s ease-in-out;
  }
  &::after {
    right: 0;
    bottom: ($navbar-toggler-bar-spacing + $navbar-toggler-bar-height) * -1;
    transition: bottom .075s .12s ease, transform .075s cubic-bezier(.55, .055, .675, .19), width .15s ease-in-out;
  }
}

.navbar-toggler:not(.active):not([aria-expanded="true"]):hover .navbar-toggler-icon {
  &::before,
  &::after {
    width: $navbar-toggler-bar-width * .75;
  }
}

.navbar-toggler.active,
[aria-expanded="true"] {
  .navbar-toggler-icon {
    transition-delay: .12s;
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: rotate(45deg);
    &::before,
    &::after {
      width: $navbar-toggler-bar-width;
    }
    &::before {
      top: 0;
      opacity: 0;
      transition: top .075s ease, opacity .075s .12s ease;
    }
    &::after {
      bottom: 0;
      transition: bottom .075s ease, transform .075s .12s cubic-bezier(.215, .61, .355, 1);
      transform: rotate(-90deg);
    }
  }
}


// Navbar search

.navbar-search {
  position: static;
}

.navbar-search-collapse {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  background-color: $light;

  .collapse-horizontal.collapsing {
    transition: initial;
  }
}

.navbar-search-form {
  margin: {
    bottom: $spacer * .625;
  }
}

@include media-breakpoint-up(md) {
  .navbar-search {
    position: relative;
  }

  .navbar-search-collapse {
    top: 0;
    right: 100%;
    left: initial;
    width: 17rem;
  }

  .navbar-search-form {
    margin: initial;
  }
}


// Navbar search button

.btn-search:not(.collapsed) {
  .btn-search-icon {
    background-image: escape-svg($btn-search-button-active-icon);
  }
}

.btn-search-icon {
  display: inline-block;
  width: $btn-search-icon-size;
  height: $btn-search-icon-size;
  background: {
    repeat: no-repeat;
    position: center;
    size: $btn-search-icon-size;
    image: escape-svg($btn-search-icon);
  }
}


// Megamenu

.dropdown-megamenu {
  .dropdown-menu {
    --#{$prefix}dropdown-padding-y: 3rem;
    --#{$prefix}dropdown-padding-x: 0;
    --#{$prefix}dropdown-spacer: 0;
    --#{$prefix}dropdown-box-shadow: 0 1.25rem 2.5rem 0 rgba(59, 60, 66, .03);
    --#{$prefix}dropdown-border-width: initial;
    --#{$prefix}dropdown-border-radius: 0;

    border-top: $border-width solid $border-color;
    animation: initial;
  }

  // Mobile
  @include media-breakpoint-down(lg) {
    .dropdown-megamenu-toggle {
      display: none;
    }
    .dropdown-menu {
      --#{$prefix}dropdown-padding-y: initial;
      --#{$prefix}dropdown-box-shadow: initial;

      position: static;
      display: block;
      border-top: initial;
    }
    .container {
      max-width: initial;
      padding: {
        right: initial;
        left: initial;
      }
    }
  }
}

// Dropdown pane

@include media-breakpoint-up(lg) {
  .dropdown-pane {
    display: none;

    &.shown {
      display: block;
    }
  }
}
